<template>
   <section id="category" class="flex flex-col items-center py-32">
        <h2 class="my-10 text-5xl md:text-6xl font-bold text-white">Category</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 place-items-center">
            <div v-for=" category of categories" :key="category.name"
                class="grid gap-5 text-center text-white place-items-center ">
                <div class="p-10">

                    <img class="w-full h-[244px]" :src="category.image" :alt="category.title">
                </div>
                <h3 class="text-3xl font-bold">{{ category.name }}</h3>
                <p class="w-3/4">{{ category.desc }}</p>
            </div>
        </div>
    </section>
    
      <section class="grid grid-flow-col grid-cols-1 md:grid-cols-2 md:gap-10 items-center">
        <div class="md:p-10">
          <img src="../assets/category2-img.png" alt="category2-img">
        </div>
        <div class="text-center flex flex-col items-center">
          <h2 class="text-5xl md:text-6xl font-bold text-white">What's that</h2>
          <div class="flex items-center gap-5">
            <h2 class="text-5xl md:text-6xl font-bold text-white">Halloween</h2>
            <img src="../assets/footer1-img.png" class="w-9 md:w-12" alt="head2">
          </div>
          <p class="mt-8 md:mt-12 text-white text-lg md:text-xl w-full md:w-[80%]">Halloween or Hallowe'en, also known as Allhalloween, All Hallows' Eve, or All Saints' Eve, is a celebration observed in many countries on 31 October, the eve of the Western Christian feast of All Hallows' Day.</p>
        </div>
      </section>
</template>
<script>
import category1 from '../assets/category1-img.png';
import category2 from '../assets/trick-treat5-img.png';
import category3 from '../assets/category3-img.png';

export default {
    name: 'Category',
    data() {
        return {
            categories: [
                {
                    name: 'Ghost',
                    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit quis nisi voluptatibus facere provident. Ut id quis minus praesentium iusto optio, dignissimos porro ducimus nulla, mollitia maiores, magni iste ipsum!",
                    image: category1,
                },
                {
                    name: 'Witch',
                    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit quis nisi voluptatibus facere provident. Ut id quis minus praesentium iusto optio, dignissimos porro ducimus nulla, mollitia maiores, magni iste ipsum!",
                    image: category3,
                },
                {
                    name: 'Pumpkin',
                    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit quis nisi voluptatibus facere provident. Ut id quis minus praesentium iusto optio, dignissimos porro ducimus nulla, mollitia maiores, magni iste ipsum!",
                    image: category2,
                },
            ]

        }
    },

}
</script>