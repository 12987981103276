<template>
    <footer>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-28">
            <div>
                <div class="flex gap-2 mb-5">
                    <h1 class="text-white font-bold text-2xl">Hallow<span class="text-secondary">ee</span>nd</h1>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.39575 7.75016C9.64867 7.75016 10.8112 8.396 11.767 9.53266C12.4774 8.77058 13.317 8.21516 14.2083 7.94391V5.16683C14.2083 4.48169 14.4804 3.82461 14.9649 3.34014C15.4494 2.85567 16.1064 2.5835 16.7916 2.5835H19.3749V5.16683H16.7916V7.94391C17.6828 8.21516 18.5224 8.77058 19.2328 9.53266C20.1887 8.396 21.3512 7.75016 22.6041 7.75016C25.8333 7.75016 28.4166 12.0902 28.4166 17.4377C28.4166 22.7852 25.8333 27.1252 22.6041 27.1252C21.3512 27.1252 20.1887 26.4793 19.2328 25.3427C18.1866 26.4793 16.8949 27.1252 15.4999 27.1252C14.1049 27.1252 12.8133 26.4793 11.767 25.3427C10.8112 26.4793 9.64867 27.1252 8.39575 27.1252C5.16659 27.1252 2.58325 22.7852 2.58325 17.4377C2.58325 12.0902 5.16659 7.75016 8.39575 7.75016ZM11.6249 12.9168L10.0103 15.8231H13.2395L11.6249 12.9168ZM19.3749 12.9168L17.7603 15.8231H20.9895L19.3749 12.9168ZM10.3333 21.9585H12.9166L14.2083 20.6668L15.4999 21.9585H18.0833L19.3749 20.6668L20.6666 21.9585L22.6041 18.0835L17.9799 18.8843L16.7916 20.6668L15.4999 19.3752H12.9166L11.6249 20.6668L10.3333 19.3752L7.74992 18.0835L10.3333 21.9585Z"
                            fill="white" />
                    </svg>
                </div>
                <p class="text-white leading-loose tracking-wide w-full md:w-[80%]">Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Elementum fringilla ultricies eget amet blandit quis sit duis. Suspendisse fames
                    venenatis volutpat urna dui. Quisque non diam mi ullamcorper.</p>
                <div class="flex justify-end md:justify-start -mt-7 md:mt-3">
                    <img class="w-12 md:-translate-x-10 scale-x-[-1] md:scale-100" src="../assets/footer2-img.png"
                        alt="">
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center gap-12 text-white">
                <div>
                    <h3 class="mb-4 font-bold text-2xl md:text-lg">About</h3>
                    <div class="flex flex-col gap-3">
                        <a href="#" class="hover:underline">About Us</a>
                        <a href="#" class="hover:underline">Feature</a>
                        <a href="#" class="hover:underline">Career</a>
                    </div>
                </div>
                <div>
                    <h3 class="mb-4 font-bold text-2xl md:text-lg">Company</h3>
                    <div class="flex flex-col gap-3">
                        <a href="#" class="hover:underline">Our Team</a>
                        <a href="#" class="hover:underline">Partners</a>
                        <a href="#" class="hover:underline">Question</a>
                    </div>
                </div>
                <div>
                    <h3 class="mb-4 font-bold text-2xl md:text-lg">Service</h3>
                    <div class="flex flex-col gap-3">
                        <a href="#" class="hover:underline">Support</a>
                        <a href="#" class="hover:underline">Feedback</a>
                        <a href="#" class="hover:underline">Contact</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid place-items-center my-20 w-full ">
            <div class="flex items-center gap-10">
                <a href="https://www.facebook.com/irmh.agency/" class="bg-secondary h-12 w-12 grid place-items-center rounded-md">
                    <img src="../assets/fb.png" alt="">
                </a>
                <a href="https://www.instagram.com/irmh_agency/" class="bg-secondary h-12 w-12 grid place-items-center rounded-md">
                    <img src="../assets/ig.png" alt="">
                </a>
                <a href="#" class="bg-secondary h-12 w-12 grid place-items-center rounded-md">
                    <img src="../assets/tw.png" alt="">
                </a>
            </div>
            <p class="md:text-lg font-medium text-center text-white mt-8 tracking-wider">
                &copy; Copyright, Halloweend 2021, All rights reserved by <a href="https://irmh.ma">IRMH AGENCY</a>.
        </p>
    </div>
</footer></template>
