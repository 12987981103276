
import tor1 from '../assets/tors/tor1-img.png';
import tor2 from '../assets/tors/tor2-img.png';
import tor3 from '../assets/tors/tor3-img.png';
import tor4 from '../assets/tors/tor4-img.png';
import tor5 from '../assets/tors/tor5-img.png';
import tor6 from '../assets/tors/tor6-img.png';
const tors = [
    {
    title: 'candy',
    image: tor1,
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id id consequat, eu faucibus nulla. Ultrices mattis lacus, sed maecenas nascetur.'
    },
    {
    title: 'Ghost',
    image: tor2,
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id id consequat, eu faucibus nulla. Ultrices mattis lacus, sed maecenas nascetur.'
    },
    {
    title: 'Grave',
    image: tor3,
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id id consequat, eu faucibus nulla. Ultrices mattis lacus, sed maecenas nascetur.'
    },
    {
    title: 'House',
    image: tor4,
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id id consequat, eu faucibus nulla. Ultrices mattis lacus, sed maecenas nascetur.'
    },
    {
    title: 'Spider',
    image: tor5,
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id id consequat, eu faucibus nulla. Ultrices mattis lacus, sed maecenas nascetur.'
    },
    {
    title: 'Bone',
    image: tor6,
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id id consequat, eu faucibus nulla. Ultrices mattis lacus, sed maecenas nascetur.'
    },
];
export default tors;