
<template>
  <div class="container">
    <Nav></Nav>
    <Home/>
    <Category/>
<TrickOrTreat/>
<Subscribe />
<Footer />
  </div>
</template>
<script>
import CategoryVue from './components/Category.vue';
import HomeVue from './components/Home.vue';
import NavVue from './components/Nav.vue';
import Subscribe from './components/Subscribe.vue';
import TrickOrTreat from './components/TrickOrTreat.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Nav: NavVue,
    Home: HomeVue,
    Category: CategoryVue,
    TrickOrTreat: TrickOrTreat,
    Subscribe: Subscribe,
   Footer:Footer,
  }
}
</script>



