<script >
import tors from '../data/TrickOrTreat';

export default {
    data() {
        return {
            Tors: tors,
        }
    },
}
</script>
<template>
   <section id="trick-or-treat" class="flex flex-col items-center py-32">
    <h2 class="mt-10 md:text-6xl  text-4xl font-bold text-white mb-28 mb-">trick-or-treat</h2>
      <div class="grid md:grid-cols-3 grid-cols-2 gap-10">
        <div 
        v-for="tor of Tors" 
        :key="tor.title"
        class="grid gap-5 p-8 text-center text-white transition-all duration-500 rounded-md md:hover:from-primary-200/50 md:hover:to-primary-200/30 md:hover:-translate-y-10 place-items-center bg-gradient-to-b from-primary-200/20 to-primary-200/10"
        
        >
               <div>
                 <img :src="tor.image" alt="">
               </div>
               <h3 class="text-2xl font-bold">{{ tor.title }}</h3>
               <p class="hidden md:block w-[90%]">{{ tor.desc }}</p>
        </div>
        
      </div>
    </section>
</template>